.DateInput input {
  border: 0;
  height: 2.3125rem;
  color: #3c3c3c;
  font-size: 0.85rem !important;
  font-weight: 400;
  font-family: "Open Sans", serif !important;
}

.DateRangePickerInput {
  border-radius: 0.1875rem;
}
