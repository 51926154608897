.DateRangePicker *,
.SingleDatePicker *,
.DayPickerRangeController * {
  box-sizing: border-box;
}

.DateInput_input {
  color: #000 !important;
  font-weight: 400 !important;
  font-size: 1rem !important;
  line-height: 1.5 !important;
  letter-spacing: 0.00938em !important;
  padding: 0;
}

.DateRangePickerInput {
  border: 1px solid #cfcfcf !important;
}

.CalendarDay__selected_span {
  background: #01c3a1 !important;
}

.DateInput_input__focused {
  outline: 0;
  background: #fff;
  padding: 0;
}

.DateRangePickerInput_arrow_svg {
  vertical-align: middle;
  fill: #cfcfcf !important;
  height: 24px;
  width: 24px;
}
