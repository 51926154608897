.table-delivery::-webkit-scrollbar {
  width: 12px;
  /* width of the entire scrollbar */
  height: 0;
}

.DateRangePickerInput {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: none !important;
  box-shadow: none !important;
}

.DateInput {
  flex: 1;
  margin-right: 8px;
  /* Space between inputs */
}

.DateInput input {
  padding: 8px;
  border-radius: 2px;
  border: 0.5px solid #080808;
  font-size: 14px;
}

.DateRangePicker_picker {
  z-index: 1000;
}

.DateRangePickerInput_arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
}

.DateRangePickerInput_arrow_svg {
  margin: 0 auto;
}
