.table-logs::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
  height: 0;
}

@media print {
  @page {
    size: A1;
  }
}
